import Swiper from 'swiper';
import { Fancybox } from '@fancyapps/ui';
import domReady from '@wordpress/dom-ready';

domReady(() => {
  Fancybox.bind('[data-fancybox]', {});

  const tabButtons = document.querySelectorAll('.tab-button');
  let mediaSwiper = null;
  if (document.querySelector('.medias-carousel__carousel')) {
    mediaSwiper = new Swiper('.medias-carousel__carousel', {
      centeredSlides: true,
      spaceBetween: 20,
      slidesPerView: 2,
      loop: true,
      pagination: {
        el: '.medias-carousel__carousel-navigation .swiper-pagination',
        type: 'progressbar',
      },
      navigation: {
        nextEl:
          '.medias-carousel__carousel-navigation__arrows .swiper-button-next',
        prevEl:
          '.medias-carousel__carousel-navigation__arrows .swiper-button-prev',
      },
    });
  }
  if (
    0 < tabButtons.length &&
    document.querySelector('.medias-carousel__carousel')
  ) {
    tabButtons.forEach(function (tabButton) {
      tabButton.addEventListener('click', function () {
        mediaSwiper.update();
      });
    });
  }
});
